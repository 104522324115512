<template>
  <div class="manualContent" ref="dnsManualContent">
    <div class="manualContentHeader">
      {{ $t("domainVerification") }}
    </div>
    <div class="manualHeaderText">
      {{ $t("txtManualHeaderText") }}
    </div>
    <div class="manualContentHeader">
      {{ $t("verifyDNSConfig") }}
    </div>
    <div class="manualContentHeaderTips">
      {{ $t("manualHeaderTitleTips") }}
    </div>
    <div class="manualContentBox">
      <div class="iconBox">
        <div class="iconBlock">1</div>
        <div class="lineStyle"></div>
      </div>
      <div class="contentBox">
        <div class="manualContentStyle">
          <div class="contentText">{{ $t("manualContent_1") }}</div>
        </div>
        <div class="manualContentInfoStyle">
          {{ $t("manualContentInfo_1") }}
        </div>
      </div>
    </div>
    <div class="manualContentBox">
      <div class="iconBox">
        <div class="iconBlock">2</div>
        <div class="lineStyle"></div>
      </div>
      <div class="contentBox">
        <div class="manualContentStyle">
          <div class="contentText">{{ $t("manualContent_5") }}</div>
        </div>
        <template v-for="(item, index) in steps">
          <div class="manualContentInfoStyle">
            <span class="countBlock">{{ index + 1 }}、</span>{{ item.text }}
          </div>
          <div class="manualContentImageStyle">
            <img
              :src="item.image"
              is-viewer-image="true"
              @click="clickImage(index)"
            />
          </div>
        </template>
      </div>
    </div>
    <div class="manualContentBox">
      <div class="iconBox">
        <div class="iconBlock">3</div>
        <div class="lineStyle"></div>
      </div>
      <div class="contentBox">
        <div class="manualContentStyle">
          <div class="contentText">{{ $t("manualContent_6") }}</div>
        </div>
        <div class="manualImageStyle" v-if="completedImage != ''">
          <img
            :src="completedImage"
            is-viewer-image="true"
            @click="clickImage(steps.length)"
          />
        </div>
      </div>
    </div>
    <div class="manualContentBox">
      <div class="iconBox">
        <div class="iconBlock">4</div>
        <div class="lineStyle"></div>
      </div>
      <div class="contentBox">
        <div class="manualContentStyle">
          <div class="contentText">{{ $t("manualContent_4") }}</div>
        </div>
        <div class="manualContentInfoStyle">
          {{ $t("manualContentInfo_10") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      steps: [
        {
          text: this.$t("manualContentInfo_2"),
        },
        {
          text: this.$t("manualContentInfo_3"),
        },
        {
          text: this.$t("manualContentInfo_4"),
        },
        {
          text: this.$t("manualContentInfo_8"),
        },
        {
          text: this.$t("manualContentInfo_9"),
        },
      ],
      completedImage: "",
    };
  },
  created() {
    const language = this.$route.query.language || 1;
    this.handlerStep(language);
  },
  methods: {
    clickImage(index) {
      try {
        const pictures = this.steps.map((item) => item.image);
        pictures.push(this.completedImage);
        ImagePreview({
          images: pictures,
          startPosition: index,
        });
      } catch (error) {}
    },
    handlerStep(language) {
      if (parseInt(language) === 1) {
        this.steps = this.steps.map((item, index) => {
          switch (index) {
            case 0:
              item.image =
                "https://imfile.melinked.com/2023/12/f35c36aa-c62d-4eac-948f-1787f44e0d70.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 1:
              item.image =
                "https://imfile.melinked.com/2023/12/db46d1f5-85d2-4d31-887d-daf42bc4706f.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 2:
              item.image =
                "https://imfile.melinked.com/2023/12/f1029d68-a03b-4009-bc5f-01dca8eea236.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 3:
              item.image =
                "https://imfile.melinked.com/2023/12/0e60db8a-d2b1-40c7-bf60-2bc423e91ee5.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 4:
              item.image =
                "https://imfile.melinked.com/2023/12/e0435fd9-9ba7-476e-b3d9-f6a167ed4cdf.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
          }
          return item;
        });
        this.completedImage =
          "https://imfile.melinked.com/2023/12/c46da154-d882-4a91-b7ae-47f4519d3c1d.png?imageView2/0/interlace/1/format/png|imageslim";
      } else {
        this.steps = this.steps.map((item, index) => {
          switch (index) {
            case 0:
              item.image =
                "https://imfile.melinked.com/2023/12/bfe98c34-b40c-4ca1-9bb7-6408ea13dcec.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 1:
              item.image =
                "https://imfile.melinked.com/2023/12/f2c261c9-6956-4414-8b77-e3ba8c72124b.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 2:
              item.image =
                "https://imfile.melinked.com/2023/12/67914b84-ff19-4d97-9959-021b08841434.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 3:
              item.image =
                "https://imfile.melinked.com/2023/12/0e60db8a-d2b1-40c7-bf60-2bc423e91ee5.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 4:
              item.image =
                "https://imfile.melinked.com/2024/01/11b90d5e-7466-434a-b514-63854c287d4f.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
          }
          return item;
        });
        this.completedImage =
          "https://tfile.melinked.com/2024/01/a6bcbf29-89b9-4c90-8027-6e855758aa22.png?imageView2/0/interlace/1/format/png|imageslim";
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.manualContent
  width: 100%;
  height: 100%;
  word-break: break-word;
  padding-bottom: 40px;
  *
    box-sizing: border-box;
  .manualContentHeader
    width: 100%;
    font-size: 18px;
    color: #52BD68;
    text-align: left;
    padding: 9px 12px;
    line-height: 24px;
    background: rgba(82, 189, 104, 0.1);
  .manualContentHeaderTips
    color: #858585;
    font-size: 14px;
    line-height: 20px;
  .manualHeaderText
    width: 100%;
    margin: 15px 0 26px 0;
    font-size: 16px;
    color: #000000;
    text-align: left;
  .manualContentBox
    display: flex;
    width: 100%;
    overflow: hidden;
    .iconBox
      width: 22px;
      flex-shrink: 0;
      padding: 19px 0 0 0;
      .iconBlock
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: #52BD68;
        text-align: center;
        line-height: 22px;
        font-size: 14px;
        color: #FFFFFF;
        margin-bottom: 6px;
      .lineStyle
        width: 1px;
        height: calc(100% - 22px);
        background: #D9D9D9;
        margin: auto;
    .contentBox
      flex: 1;
      min-width: 0;
      overflow: hidden;
      margin-left: 7px;
      .manualContentStyle
        width: 100%;
        padding: 18px 0 0 0;
        .contentText
          min-width: 0;
          min-height: 24px;
          line-height: 22px;
          font-size: 16px;
          color: #000000;
      .manualContentInfoStyle
        width: 100%;
        margin: 8px 0 0 0;
        font-size: 16px;
        line-height: 22px;
        color: #8F8F8F;
        .countBlock
          display: inline-block;
          width: 30px;
      .manualContentImageStyle
        width: 90%;
        min-height: 0;
        margin: 8px 0 16px 0;
        img
          width: 100%;
          display: block;
      .manualImageStyle
        width: 90%;
        min-height: 0;
        margin: 8px 0 24px 0;
        img
          width: 100%;
          display: block;
</style>
