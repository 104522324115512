<template>
  <div class="verifyDomain">
    <TXTManual v-if="active == 0" />
    <DNSManual v-if="active == 1" />
  </div>
</template>
<script>
import TXTManual from "../../components/verifyDomain/TXTManual.vue";
import DNSManual from "../../components/verifyDomain/DNSManual.vue";
export default {
  components: {
    TXTManual,
    DNSManual,
  },
  computed: {
    langs() {
      const messages = this.$i18n.messages;
      let langs = [];
      for (const langName in messages) {
        const curmsg = messages[langName];
        let name = messages["zh-CN"].langs[langName];
        let item = {
          text: `${name}(${curmsg.langName})`,
          fulltext: [name, curmsg.langName],
          value: langName,
          code: curmsg.code,
          encode: curmsg.encode,
        };
        langs.push(item);
      }
      return langs;
    },
  },
  data() {
    return {
      active: -1,
    };
  },
  created() {
    this.active = this.$route.query.active;
    const inputlang = this.$route.query.language || 1;
    const language = this.langs.filter((lang) => lang.code == inputlang)[0];
    if (language.value != "zh-CN" && language.value != "en") {
      this.$i18n.locale = "en";
    } else {
      this.$i18n.locale = language.value;
    }
  },
};
</script>
<style lang="stylus" scoped>
.verifyDomain
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 24px 15px;
</style>
