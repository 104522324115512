<template>
  <div class="manualContent" ref="txtManualContent">
    <div class="manualContentHeader">
      {{ $t("domainVerification") }}
    </div>
    <div class="manualHeaderText">
      {{ $t("txtManualHeaderText") }}
    </div>
    <div class="manualContentHeader">
      {{ $t("manualHeaderTitle") }}
    </div>
    <div class="manualContentHeaderTips">
      {{ $t("manualHeaderTitleTips") }}
    </div>
    <div class="manualContentBox">
      <div class="iconBox">
        <div class="iconBlock">1</div>
        <div class="lineStyle"></div>
      </div>
      <div class="contentBox">
        <div class="manualContentStyle">
          <div class="contentText">{{ $t("manualContent_1") }}</div>
        </div>
        <div class="manualContentInfoStyle">
          {{ $t("manualContentInfo_1") }}
        </div>
      </div>
    </div>
    <div class="manualContentBox">
      <div class="iconBox">
        <div class="iconBlock">2</div>
        <div class="lineStyle"></div>
      </div>
      <div class="contentBox">
        <div class="manualContentStyle">
          <div class="contentText">{{ $t("manualContent_2") }}</div>
        </div>
        <template v-for="(item, index) in steps">
          <div class="manualContentInfoStyle">
            <span class="countBlock">{{ index + 1 }}、</span>{{ item.text }}
          </div>
          <div class="manualContentImageStyle">
            <img
              :src="item.image"
              is-viewer-image="true"
              @click="clickImage(index)"
            />
          </div>
        </template>
      </div>
    </div>
    <div class="manualContentBox">
      <div class="iconBox">
        <div class="iconBlock">3</div>
        <div class="lineStyle"></div>
      </div>
      <div class="contentBox">
        <div class="manualContentStyle">
          <div class="contentText">{{ $t("manualContent_3") }}</div>
        </div>
        <div class="manualImageStyle" v-if="completedImage != ''">
          <img
            :src="completedImage"
            is-viewer-image="true"
            @click="clickImage(steps.length)"
          />
        </div>
      </div>
    </div>
    <div class="manualContentBox">
      <div class="iconBox">
        <div class="iconBlock">4</div>
        <div class="lineStyle"></div>
      </div>
      <div class="contentBox">
        <div class="manualContentStyle">
          <div class="contentText">{{ $t("manualContent_4") }}</div>
        </div>
        <div class="manualContentInfoStyle">
          {{ $t("manualContentInfo_7") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      steps: [
        {
          text: this.$t("manualContentInfo_2"),
        },
        {
          text: this.$t("manualContentInfo_3"),
        },
        {
          text: this.$t("manualContentInfo_4"),
        },
        {
          text: this.$t("manualContentInfo_5"),
        },
        {
          text: this.$t("manualContentInfo_6"),
        },
      ],
      completedImage: "",
    };
  },
  created() {
    const language = this.$route.query.language || 1;
    this.handlerStep(language);
  },
  methods: {
    clickImage(index) {
      try {
        const pictures = this.steps.map((item) => item.image);
        pictures.push(this.completedImage);
        ImagePreview({
          images: pictures,
          startPosition: index,
        });
      } catch (error) {}
    },
    handlerStep(language) {
      console.log(parseInt(language));
      if (parseInt(language) === 1) {
        this.steps = this.steps.map((item, index) => {
          switch (index) {
            case 0:
              item.image =
                "https://imfile.melinked.com/2023/12/f35c36aa-c62d-4eac-948f-1787f44e0d70.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 1:
              item.image =
                "https://imfile.melinked.com/2023/12/db46d1f5-85d2-4d31-887d-daf42bc4706f.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 2:
              item.image =
                "https://imfile.melinked.com/2023/12/f1029d68-a03b-4009-bc5f-01dca8eea236.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 3:
              item.image =
                "https://imfile.melinked.com/2023/12/86845691-77a3-4438-aaa2-e4c212fcf10c.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 4:
              item.image =
                "https://imfile.melinked.com/2023/12/ca2aaefb-1bd9-4034-8c31-8a9bceb5c938.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
          }
          return item;
        });
        this.completedImage =
          "https://imfile.melinked.com/2023/12/f533affc-459c-495b-a15a-29571f2b4b96.png?imageView2/0/interlace/1/format/png|imageslim";
      } else {
        this.steps = this.steps.map((item, index) => {
          switch (index) {
            case 0:
              item.image =
                "https://imfile.melinked.com/2023/12/47008550-a584-4af8-a488-86923b66e976.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 1:
              item.image =
                "https://imfile.melinked.com/2023/12/c83cd751-0c05-49db-88b4-9479c9d87d61.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 2:
              item.image =
                "https://imfile.melinked.com/2023/12/33b88798-b38a-4f13-83b5-4f8311b0bfbb.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 3:
              item.image =
                "https://imfile.melinked.com/2024/01/1ebff8b8-400c-4eeb-b480-75f82fb5e845.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
            case 4:
              item.image =
                "https://imfile.melinked.com/2023/12/cf88e2b1-82f1-42bd-9795-24c842e090ec.png?imageView2/0/interlace/1/format/png|imageslim";
              break;
          }
          return item;
        });
        this.completedImage =
          "https://tfile.melinked.com/2024/01/7c5cca5f-971c-4614-8fc7-73ca449ad50b.png?imageView2/0/interlace/1/format/png|imageslim";
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.manualContent
  width: 100%;
  height: 100%;
  word-break: break-word;
  padding-bottom: 40px;
  *
    box-sizing: border-box;
  .manualContentHeader
    width: 100%;
    font-size: 18px;
    color: #52BD68;
    text-align: left;
    padding: 9px 12px;
    line-height: 24px;
    background: rgba(82, 189, 104, 0.1);
  .manualContentHeaderTips
    color: #858585;
    font-size: 14px;
    line-height: 20px;
  .manualHeaderText
    width: 100%;
    margin: 15px 0 26px 0;
    font-size: 16px;
    color: #000000;
    text-align: left;
  .manualContentBox
    display: flex;
    width: 100%;
    overflow: hidden;
    .iconBox
      width: 22px;
      flex-shrink: 0;
      padding: 19px 0 0 0;
      .iconBlock
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: #52BD68;
        text-align: center;
        line-height: 22px;
        font-size: 14px;
        color: #FFFFFF;
        margin-bottom: 6px;
      .lineStyle
        width: 1px;
        height: calc(100% - 22px);
        background: #D9D9D9;
        margin: auto;
    .contentBox
      flex: 1;
      min-width: 0;
      overflow: hidden;
      margin-left: 7px;
      .manualContentStyle
        width: 100%;
        padding: 18px 0 0 0;
        .contentText
          min-width: 0;
          min-height: 24px;
          line-height: 22px;
          font-size: 16px;
          color: #000000;
      .manualContentInfoStyle
        width: 100%;
        margin: 8px 0 0 0;
        font-size: 16px;
        line-height: 22px;
        color: #8F8F8F;
        .countBlock
          display: inline-block;
          width: 30px;
      .manualContentImageStyle
        width: 90%;
        min-height: 0;
        margin: 8px 0 16px 0;
        img
          width: 100%;
          display: block;
      .manualImageStyle
        width: 90%;
        min-height: 0;
        margin: 8px 0 24px 0;
        img
          width: 100%;
          display: block;
</style>
