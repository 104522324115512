var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "txtManualContent", staticClass: "manualContent" }, [
    _c("div", { staticClass: "manualContentHeader" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("domainVerification")) + "\n  ")
    ]),
    _c("div", { staticClass: "manualHeaderText" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("txtManualHeaderText")) + "\n  ")
    ]),
    _c("div", { staticClass: "manualContentHeader" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("manualHeaderTitle")) + "\n  ")
    ]),
    _c("div", { staticClass: "manualContentHeaderTips" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("manualHeaderTitleTips")) + "\n  ")
    ]),
    _c("div", { staticClass: "manualContentBox" }, [
      _vm._m(0),
      _c("div", { staticClass: "contentBox" }, [
        _c("div", { staticClass: "manualContentStyle" }, [
          _c("div", { staticClass: "contentText" }, [
            _vm._v(_vm._s(_vm.$t("manualContent_1")))
          ])
        ]),
        _c("div", { staticClass: "manualContentInfoStyle" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.$t("manualContentInfo_1")) + "\n      "
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "manualContentBox" }, [
      _vm._m(1),
      _c(
        "div",
        { staticClass: "contentBox" },
        [
          _c("div", { staticClass: "manualContentStyle" }, [
            _c("div", { staticClass: "contentText" }, [
              _vm._v(_vm._s(_vm.$t("manualContent_2")))
            ])
          ]),
          _vm._l(_vm.steps, function(item, index) {
            return [
              _c("div", { staticClass: "manualContentInfoStyle" }, [
                _c("span", { staticClass: "countBlock" }, [
                  _vm._v(_vm._s(index + 1) + "、")
                ]),
                _vm._v(_vm._s(item.text) + "\n        ")
              ]),
              _c("div", { staticClass: "manualContentImageStyle" }, [
                _c("img", {
                  attrs: { src: item.image, "is-viewer-image": "true" },
                  on: {
                    click: function($event) {
                      return _vm.clickImage(index)
                    }
                  }
                })
              ])
            ]
          })
        ],
        2
      )
    ]),
    _c("div", { staticClass: "manualContentBox" }, [
      _vm._m(2),
      _c("div", { staticClass: "contentBox" }, [
        _c("div", { staticClass: "manualContentStyle" }, [
          _c("div", { staticClass: "contentText" }, [
            _vm._v(_vm._s(_vm.$t("manualContent_3")))
          ])
        ]),
        _vm.completedImage != ""
          ? _c("div", { staticClass: "manualImageStyle" }, [
              _c("img", {
                attrs: { src: _vm.completedImage, "is-viewer-image": "true" },
                on: {
                  click: function($event) {
                    return _vm.clickImage(_vm.steps.length)
                  }
                }
              })
            ])
          : _vm._e()
      ])
    ]),
    _c("div", { staticClass: "manualContentBox" }, [
      _vm._m(3),
      _c("div", { staticClass: "contentBox" }, [
        _c("div", { staticClass: "manualContentStyle" }, [
          _c("div", { staticClass: "contentText" }, [
            _vm._v(_vm._s(_vm.$t("manualContent_4")))
          ])
        ]),
        _c("div", { staticClass: "manualContentInfoStyle" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.$t("manualContentInfo_7")) + "\n      "
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "iconBox" }, [
      _c("div", { staticClass: "iconBlock" }, [_vm._v("1")]),
      _c("div", { staticClass: "lineStyle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "iconBox" }, [
      _c("div", { staticClass: "iconBlock" }, [_vm._v("2")]),
      _c("div", { staticClass: "lineStyle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "iconBox" }, [
      _c("div", { staticClass: "iconBlock" }, [_vm._v("3")]),
      _c("div", { staticClass: "lineStyle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "iconBox" }, [
      _c("div", { staticClass: "iconBlock" }, [_vm._v("4")]),
      _c("div", { staticClass: "lineStyle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }